<template>
  <div>
    <Header :showBackButton="false" class="page-anim1" />

    <section>
      <div class="container">
        <div class="filters">
          <div class="search-item">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Pesquisar produtos"
            />
            <Magnify class="pointer" @click="search" />
          </div>
          <select @change="search" v-model="selectedCategory">
            <option value="">Categoria: Todas</option>
            <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{
              cat.name
            }}</option>
          </select>
        </div>

        <div class="title mt-3 tablet-hidden mobile-hidden animated fadeInLeft">
          Produtos
        </div>

        <transition enter-active-class="animated zoomIn">
          <div
            v-show="products.length > 0"
            class="products"
            v-infinite-scroll="getProducts"
            infinite-scroll-disabled="allProductsLoaded"
            infinite-scroll-distance="10"
          >
            <div
              v-for="(product, index) in products"
              :key="product.id"
              class="product"
              :class="{ inverse: index % 2 !== 0 }"
            >
              <div
                class="img-holder pointer"
                @click="$router.push(`/produto/${product.slug}`)"
              >
                <div class="shape"></div>
                <img :src="product.image" :alt="product.name" />
              </div>
              <div class="content">
                <h1 @click="$router.push(`/produto/${product.id}`)">
                  {{ product.name }}
                </h1>
                <p @click="$router.push(`/produto/${product.id}`)">
                  {{ product.summary }}
                </p>
                <router-link :to="`/produto/${product.slug}`"
                  >Saiba mais</router-link
                >
              </div>
            </div>
          </div>
        </transition>

        <div
          v-show="loading"
          class="flex-center desktop12 tablet12 mobile12 my-2"
        >
          <div class="loading2"></div>
        </div>
      </div>
    </section>

    <Navbar class="desktop-hidden" />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import RecipeCard from "../components/RecipeCard";
import Magnify from "vue-material-design-icons/Magnify.vue";

export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    RecipeCard,
    Magnify,
  },
  data() {
    return {
      loading: false,
      selectedCategory: "",
      searchQuery: "",
      products: [],
      categories: [],
      allProductsLoaded: false,
      take: 18,
      page: 1,
    };
  },
  created() {
    this.getCategories();
    this.getProducts();
  },
  methods: {
    getProducts() {
      if (this.loading || this.allProductsLoaded) return;

      this.loading = true;
      http
        .get(
          `product/list?take=${this.take}&page=${this.page}&categoryId=${this.selectedCategory}&search=${this.searchQuery}&orderBy=timestamp`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (this.page < response.data.pages) {
            this.page++;
          } else {
            this.allProductsLoaded = true;
          }
          this.products = [...this.products, ...response.data.list];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.products = [];
      this.page = 1;
      this.allProductsLoaded = false;
      this.getProducts();
    },
    getCategories() {
      http
        .get("productCategory/list?take=-1")
        .then((response) => {
          this.categories = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.filters {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .search-item {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--secondary);
    input {
      position: relative;
      width: calc(100% - 30px);
      font-size: 1.05em;
      &::placeholder {
        font-size: 1.05em;
      }
    }
  }
  select {
    position: relative;
    width: 40%;
    height: 28px;
    color: var(--dark5);
    font-size: 1.05em;
    border-bottom: 1px solid var(--secondary);
  }
}

.products {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.product {
  --product-height: 140px;
  position: relative;
  width: 100%;
  height: var(--product-height);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  &.inverse {
    flex-direction: row-reverse;
    .img-holder {
      .shape {
        left: initial;
        right: -50%;
      }
      img {
        margin-left: auto;
      }
    }
  }
  .img-holder {
    position: relative;
    width: 140px;
    height: var(--product-height);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .shape {
      position: absolute;
      width: 100%;
      height: 100%;
      left: -50%;
      background-color: var(--yellow1);
      border-radius: 100%;
    }
    img {
      position: relative;
      max-width: 80%;
      max-height: 80%;
    }
  }
  .content {
    position: relative;
    width: calc(100% - 140px);
    h1 {
      font-size: 1.2em;
      color: var(--dark4);
      cursor: pointer;
    }
    p {
      position: relative;
      font-size: 1.05em;
      line-height: 1.4em;
      max-height: 4.2em;
      overflow: hidden;
      margin: 8px 8px 0 0;
      cursor: pointer;
    }
    a {
      position: relative;
      display: block;
      font-size: 1.1em;
      color: var(--secondary);
      margin-top: 10px;
    }
  }
}

@media screen and (min-width: 1120px) {
  .product {
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    &.inverse {
      flex-direction: row;
      .img-holder {
        img {
          margin-left: initial;
        }
        .shape {
          margin-right: initial;
          left: -50%;
        }
      }
    }
  }
}
</style>
